import styled from "styled-components";
import { ReactComponent as CorpName } from "../../assets/icons/corp_name.svg";
import { ReactComponent as HeaderName } from "../../assets/icons/header_name.svg";
import { ReactComponent as CreateDate } from "../../assets/icons/create_date.svg";
import { ReactComponent as Contents } from "../../assets/icons/contents.svg";
import { ReactComponent as Maps } from "../../assets/icons/maps.svg";
import { ReactComponent as Contact } from "../../assets/icons/contacts.svg";
import { media } from "../../utils/media";
import { Suspense } from "react";
import AboutUsItem from "./AboutUsItem";
import fetchCData from "../../service/company/fetchData";

const AboutUs = ({ resource }) => {
  return (
    <Wrap className={"bg-mainblue"}>
      <p
        className="au-header text-center text-white text-[30px] pb-[77px]"
        style={{ fontWeight: "bold", fontFamily: "SB font" }}
      >
        ABOUT US
      </p>
      <div className={"about-item-list grid grid-cols-3 pb-[208px]"}>
        <Suspense fallback={<div>..loading</div>}>
          <AboutUsItem resource={resource} />
        </Suspense>
      </div>
    </Wrap>
  );
};

const Wrap = styled.div`
  padding-top: 110px;
  padding-left: 260px;
  padding-right: 260px;

  ${media.large`
  padding-left: 130px;
  padding-right: 130px;
  
  `}

  ${media.mLarge`
  padding-left:25px;
  padding-right:25px;
  `}

  >p.au-header {
    text-align: center;
    color: white;
    font-size: 30px;
    padding-bottom: 77px;

    ${media.mLarge`
    font-size:20px;
    padding-bottom:50px;
    `}
  }

  .about-item-list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 100px;

    ${media.mLarge`
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap:50px;
    
    `}
  }
`;

const AboutItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  > div.ai-item {
    padding-top: 55px;
    text-align: center;

    p {
      line-height: 1;
      &.title {
        font-size: 30px;
        font-weight: bold;
        padding-bottom: 25px;
        color: #ffffff;

        ${media.large`
          font-size:25px;
      `}

        ${media.mLarge`
      font-size:20px;
      `}
      }
    }

    span {
      display: block;
      line-height: 1;
      font-size: 20px;
      font-weight: normal;
      color: #ffffff;

      ${media.large`
        font-size:15px;
      `}

      ${media.mLarge`
        font-size:12px;
      `}
    }
  }
`;

export default AboutUs;
